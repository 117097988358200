import { Col, Form, Input, Modal, notification, Row, Select } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import moment from "moment";

const AssignTransactionBalanceModal = ({ isModalOpen, setModalOpen, transactions, setTransactions, transaction }) => {
  const { t } = useTranslation();

  const [balances, setBalances] = useState([]);

  const [assignForm] = Form.useForm();
  const [infoForm] = Form.useForm();

  useEffect(() => {
    coreApi
      .get("balances/all")
      .then((response) => {
        const sortedBalances = response.data.sort((a, b) => {
          const nameA = (a.balanceable?.first_name || '').toLowerCase();
          const nameB = (b.balanceable?.first_name || '').toLowerCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;

          return new Date(b.created_at) - new Date(a.created_at);
        });

        setBalances(sortedBalances);
      })
      .catch((e) => {
        console.log(e);
      });

    infoForm.resetFields();
    assignForm.resetFields();
  }, [transaction]);

  return (
    <Modal
      key={transaction?.id}
      title={t("finance.transactions.assignTransaction", { transaction_number: transaction.id })}
      open={isModalOpen}
      width={800}
      onCancel={() => {
        infoForm.resetFields();
        assignForm.resetFields();
        setModalOpen(false);
      }}
      okText={t("finance.transactions.assign")}
      onOk={() => {
        assignForm.submit();
      }}
    >
      <Form
        form={assignForm}
        layout={"vertical"}
        onFinish={(values) => {
          assignForm.validateFields().then((fields) => {
            setModalOpen(false);
            coreApi
              .post("/transactions/assign-to-balance", { ...fields })
              .then((res) => {
                notification.success({
                  message: res.data.message,
                });

                setTransactions([...transactions.filter((transaction) => transaction.id !== values.transaction_id)]);

                assignForm.resetFields();
              })
              .catch((err) => console.log(err));
          });
        }}
      >
        <Row gutter={[16, 24]}>
          <Col span={8}>
            <Form.Item
              name={"transaction_id"}
              label={t("finance.transactions.transactionNumber")}
              initialValue={transaction.id}
            >
              <Input readOnly={true} bordered={false}></Input>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={t("finance.transactions.assignToBlanace")}
              name="balance_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder={t("finance.balances.balances")}
                showSearch
                optionFilterProp="key"
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {balances.map((balance) => {
                  return (
                    <Select.Option
                      key={
                        moment(balance?.created_at).format("MM") +
                        "/" +
                        moment(balance?.created_at).format("YY") +
                        " " +
                        balance?.balanceable?.first_name +
                        " " +
                        balance?.balanceable?.last_name +
                        " " +
                        balance?.id
                      }
                      value={balance?.id}
                    >
                      {moment(balance?.created_at).format("MM") +
                        "/" +
                        moment(balance?.created_at).format("YY") +
                        " " +
                        balance?.balanceable?.first_name +
                        " " +
                        balance?.balanceable?.last_name +
                        (balance?.balanceable_type === "worker" ? " (worker)" : "")}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AssignTransactionBalanceModal;
